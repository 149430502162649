.bottom-section {
  border: 1px solid #23262f;
}
.game-over-wrapper {
  background-image: url(../assets/images/hero-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}
.gameover-text{
  font-family: Minercraftory Regular;
  --tw-text-opacity: 1;
  color: rgba(252,236,96,var(--tw-text-opacity));
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 20px;
}

.sqm-logo img {
  max-width: 500px;
  min-width: 200px;
}