.metamask {
  border: 1px solid #353945;
  padding: 8px 14px;
  border-radius: 35px;
  /* background-color: #1f1f25; */
}

.menu .metamask {
  border: 2px solid #353945;
}

.metamask-mobile {
  background-color: #26272d;
  padding: 10px 16px;
  border-radius: 35px;
}

.select .MuiOutlinedInput-notchedOutline {
  border-color: white;
  border-radius: 30px;
}

.select .MuiSvgIcon-root {
  fill: white;
}

body .MuiList-root.MuiList-padding.MuiMenu-list {
  background-color: #000;
}

.menu {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  max-width: 350px;
  width: 100%;
  background-color: #141416;
  z-index: 99999;
  padding: 20px 30px;
  transition: all 0.3s;
}

.menu.active {
  right: 0;
}

.menu-social {
  padding: 20px 0;
}

.menu-social a {
  font-size: 30px;
  margin: 0 20px;
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 8888;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 137px;
  height: 40px;
  left: 1041px;
  top: 41px;

  /* Neutrals/4 */

  border: 2px solid #777E91;
  box-sizing: border-box;
  border-radius: 90px;
}

/* equaliser */
.equaliser {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.equaliser-bar {
  float: left;
  width: 25%;
  height: 100%;
  position: relative;
}

.equaliser-bar:after {
  position: absolute;
  left: 0;
  right: 20%;
  /* space between bars */
  bottom: 0;
  content: "";
  background: #b8b6ba;
}

.equaliser-bar:nth-child(4n+1):after {
  animation: equaliser-wave 2s 1s ease-out alternate infinite;
}

.equaliser-bar:nth-child(4n+2):after {
  animation: equaliser-wave 2s 0.5s ease-out alternate infinite;
}

.equaliser-bar:nth-child(4n+3):after {
  animation: equaliser-wave 2s 1.5s ease-out alternate infinite;
}

.equaliser-bar:nth-child(4n+4):after {
  animation: equaliser-wave 2s 0.25s ease-out alternate infinite;
}

@keyframes equaliser-wave {
  0% {
    height: 10%;
  }

  10% {
    height: 30%;
  }

  20% {
    height: 50%;
  }

  30% {
    height: 20%;
  }

  40% {
    height: 70%;
  }

  50% {
    height: 90%;
  }

  60% {
    height: 30%;
  }

  70% {
    height: 80%;
  }

  80% {
    height: 50%;
  }

  90% {
    height: 30%;
  }

  100% {
    height: 10%;
  }
}

/* end equaliser */
.equaliser {
  height: 30px;
  width: 20px;
}

@media (max-width: 768px) {
  .cus_mobile_adjust .font-mineCraft {
    margin: 0;
    font-size: 1.3rem;
  }
}
.menu-container {
  position: relative;
}
.lan-menu {
  background: #000000;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 230px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.lan-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  z-index: 100;
}

.lan-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 1rem;
}
.lan-menu li {
  display: flex;
  align-items: center;
}
.lan-menu li img{
  width: 30px;
  height: 30px;
}
.lan-menu li div {
  color: #ffffff;
  padding: 15px 20px;
  cursor: pointer;
}
.menu-trigger {
  cursor: pointer;
}