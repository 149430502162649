.hero-section {
  background-image: url(../../assets/images/hero-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 70px;
}

/* @media (max-width: 768px) {
  .hero-section {
  }
} */
.marbles-question {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: #f3ba2f;
  color: black;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minecraft-dollor {
  position: relative;
}

.minecraft-dollor::after,
.minecraft-dollor::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 10px;
  left: 50%;

  background-color: #fcec60;
}

.minecraft-dollor::after {
  transform: translate(-50%, 100%);
  top: 0;
}

.minecraft-dollor::before {
  bottom: 0;
  transform: translate(-50%, -100%);
}

.even,
.odd {
  padding: 120px 10px;
  border-radius: 30px;
  font-weight: bold;
  text-align: center;
  font-size: 80px;
  overflow: hidden;
  box-shadow: 4px 38px 63px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.even p,
.odd p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.even.disabled,
.odd.disabled {
  opacity: .4 !important;
  cursor: not-allowed;
  background: #5c5938;
  cursor: not-allowed;
}

.even-hover,
.odd-hover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity linear 0.3s;
}

.even-bg,
.odd-bg {
  transition: opacity linear 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.even:hover .even-bg,
.odd:hover .odd-bg {
  opacity: 0;
}

.even:hover .even-hover,
.odd:hover .odd-hover {
  opacity: 1;
}

@keyframes backgroundIMG {
  100% {
    background-image: url(../../assets/images/EVEN_BG-HOVER.png);
  }
}

.odd:hover {
  /*background-image: url(../../assets/images/ODD_BG-HOVER.png);*/
}

@keyframes backgroundIMG2 {
  100% {
    background-image: url(../../assets/images/ODD_BG-HOVER.png);
  }
}

.odd {
  background-image: url(../../assets/images/ODD_BG.png);
  background-position: right bottom;
}

.or {
  clip-path: polygon(30% 0%,
      70% 0%,
      100% 30%,
      100% 70%,
      70% 100%,
      30% 100%,
      0% 70%,
      0% 30%);

  background-color: #f3ba2f;
  padding: 10px;
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;

  justify-content: center;
  align-items: center;
}

.select-bnb {
  font-weight: bold;
  color: #9A9FA5;
  width: 90%;
}

@media (min-width: 768px) {
  .select-bnb {
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .select-bnb {
    width: 30%;
  }
}

@media (min-width: 1240px) {
  .select-bnb {
    width: 200px;
  }
}

@media (min-width: 1366px) {
  .select-bnb {
    width: 235px;
  }
}

.select-bnb-left {
  color: #FCEC60;
  width: 55%;
  background-image: url(../../assets/images/select-bnb-bg.png);
  background-size: 100% 100%;
  padding: 18px 0;
  margin: -8px 0;
}

.select-bnb-right {
    width: 45%;
}

.select-bnb-left img {
    vertical-align: baseline;
}

.select-bnb.active .select-bnb-left {
    color: black;
    background-image: url(../../assets/images/select-bnb-bg-active.png);
}

.select-bnb.active .select-bnb-right {
    color: white;
}

.tick {
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(30%, -30%);
  display: none;
}

.select-bnb.active .tick {
  display: flex;
}

.pending_approve {
  Padding: 30px;
  background: linear-gradient(174deg, #353535, #00000030);
  border-radius: 20px;
  text-align: center;
  margin-top: 50px
}

.pending_approve p {
  color: #888;
}

.pending_approve a {
  color: #888;
  cursor: pointer;

}

.pending_approve .loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #f2d900;
  width: 26px;
  height: 26px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin-right: 15px
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error_sec h2 {
  padding: 0px 30px;
}

.error_sec .close_btn {
  padding: 5px 15px;
  border-radius: 30px;
  border: 1px solid #fff;
  font-size: 14px;
}

.tooltips {
  position: absolute;
  font-size: 14px;
  text-align: center;
  width: 100%;
  bottom: 32px;
  display: none;
}

.tooltips span {
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .even, .odd {
    padding: 70px 10px;
    font-size: 35px;
    border-radius: 18px;
  }

  .cus_mobile_adjust .mt-14 {
      margin-top: 1.5rem;
    }
    .cus_mobile_adjust .gap-10 {
      gap: 1.5rem;
  }
  .cus_mobile_adjust .mt-16 {
    margin-top: 1.5rem;
}
}